import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _e0f3bc5a = () => interopDefault(import('../pages/achievement/index.vue' /* webpackChunkName: "pages/achievement/index" */))
const _bbb039ea = () => interopDefault(import('../pages/advertise/index.vue' /* webpackChunkName: "pages/advertise/index" */))
const _6e3965a4 = () => interopDefault(import('../pages/agreement/index.vue' /* webpackChunkName: "pages/agreement/index" */))
const _20031f30 = () => interopDefault(import('../pages/calendar/index.vue' /* webpackChunkName: "pages/calendar/index" */))
const _3f605bd0 = () => interopDefault(import('../pages/customer/index.vue' /* webpackChunkName: "pages/customer/index" */))
const _647ed4b4 = () => interopDefault(import('../pages/details/index.vue' /* webpackChunkName: "pages/details/index" */))
const _468a4038 = () => interopDefault(import('../pages/goals/index.vue' /* webpackChunkName: "pages/goals/index" */))
const _48732082 = () => interopDefault(import('../pages/maisoku/index.vue' /* webpackChunkName: "pages/maisoku/index" */))
const _61950f08 = () => interopDefault(import('../pages/media/index.vue' /* webpackChunkName: "pages/media/index" */))
const _5c2d03a9 = () => interopDefault(import('../pages/privacy.vue' /* webpackChunkName: "pages/privacy" */))
const _6187e486 = () => interopDefault(import('../pages/project/index.vue' /* webpackChunkName: "pages/project/index" */))
const _1c7e1073 = () => interopDefault(import('../pages/response/index.vue' /* webpackChunkName: "pages/response/index" */))
const _292f5e60 = () => interopDefault(import('../pages/achievement/output.vue' /* webpackChunkName: "pages/achievement/output" */))
const _e429c170 = () => interopDefault(import('../pages/achievement/registration.vue' /* webpackChunkName: "pages/achievement/registration" */))
const _729ef19a = () => interopDefault(import('../pages/advertise/analysis.vue' /* webpackChunkName: "pages/advertise/analysis" */))
const _339acd5a = () => interopDefault(import('../pages/advertise/management.vue' /* webpackChunkName: "pages/advertise/management" */))
const _1f7fe1e0 = () => interopDefault(import('../pages/advertise/registration.vue' /* webpackChunkName: "pages/advertise/registration" */))
const _0014cf4b = () => interopDefault(import('../pages/calendar/registration.vue' /* webpackChunkName: "pages/calendar/registration" */))
const _01581eab = () => interopDefault(import('../pages/customer/registration.vue' /* webpackChunkName: "pages/customer/registration" */))
const _1dceafba = () => interopDefault(import('../pages/goals/analysis.vue' /* webpackChunkName: "pages/goals/analysis" */))
const _fdcb403c = () => interopDefault(import('../pages/goals/setting.vue' /* webpackChunkName: "pages/goals/setting" */))
const _3191af16 = () => interopDefault(import('../pages/line/tracking/index.vue' /* webpackChunkName: "pages/line/tracking/index" */))
const _7f972997 = () => interopDefault(import('../pages/maisoku/export.vue' /* webpackChunkName: "pages/maisoku/export" */))
const _2e50e89d = () => interopDefault(import('../pages/maisoku/template.vue' /* webpackChunkName: "pages/maisoku/template" */))
const _11877ca8 = () => interopDefault(import('../pages/response/registration.vue' /* webpackChunkName: "pages/response/registration" */))
const _d8852bd0 = () => interopDefault(import('../pages/setting/announcement/index.vue' /* webpackChunkName: "pages/setting/announcement/index" */))
const _23a94376 = () => interopDefault(import('../pages/setting/import/index.vue' /* webpackChunkName: "pages/setting/import/index" */))
const _66d5fe32 = () => interopDefault(import('../pages/setting/mail-template.vue' /* webpackChunkName: "pages/setting/mail-template" */))
const _0022172e = () => interopDefault(import('../pages/setting/matching-mail.vue' /* webpackChunkName: "pages/setting/matching-mail" */))
const _c52a28f8 = () => interopDefault(import('../pages/setting/shop.vue' /* webpackChunkName: "pages/setting/shop" */))
const _87762f76 = () => interopDefault(import('../pages/setting/staff/index.vue' /* webpackChunkName: "pages/setting/staff/index" */))
const _facf6b06 = () => interopDefault(import('../pages/setting/step-mail/index.vue' /* webpackChunkName: "pages/setting/step-mail/index" */))
const _d8206202 = () => interopDefault(import('../pages/setting/tag/index.vue' /* webpackChunkName: "pages/setting/tag/index" */))
const _b21358f6 = () => interopDefault(import('../pages/shop/document-template/index.vue' /* webpackChunkName: "pages/shop/document-template/index" */))
const _cb7d7340 = () => interopDefault(import('../pages/shop/message/index.vue' /* webpackChunkName: "pages/shop/message/index" */))
const _7d7df59e = () => interopDefault(import('../pages/message/line/edit.vue' /* webpackChunkName: "pages/message/line/edit" */))
const _5f97fa60 = () => interopDefault(import('../pages/message/line/log.vue' /* webpackChunkName: "pages/message/line/log" */))
const _7f5a84e0 = () => interopDefault(import('../pages/message/line/registration/index.vue' /* webpackChunkName: "pages/message/line/registration/index" */))
const _7e7a6490 = () => interopDefault(import('../pages/message/line/select.vue' /* webpackChunkName: "pages/message/line/select" */))
const _685b681c = () => interopDefault(import('../pages/message/line/send.vue' /* webpackChunkName: "pages/message/line/send" */))
const _0893dd23 = () => interopDefault(import('../pages/message/mail/log.vue' /* webpackChunkName: "pages/message/mail/log" */))
const _2b81652c = () => interopDefault(import('../pages/message/mail/registration.vue' /* webpackChunkName: "pages/message/mail/registration" */))
const _56b3e6ed = () => interopDefault(import('../pages/message/mail/select.vue' /* webpackChunkName: "pages/message/mail/select" */))
const _4248448e = () => interopDefault(import('../pages/message/mail/send.vue' /* webpackChunkName: "pages/message/mail/send" */))
const _0223ef63 = () => interopDefault(import('../pages/setting/announcement/registration.vue' /* webpackChunkName: "pages/setting/announcement/registration" */))
const _70df1f76 = () => interopDefault(import('../pages/setting/import/registration.vue' /* webpackChunkName: "pages/setting/import/registration" */))
const _c7acdc62 = () => interopDefault(import('../pages/setting/real-estate-agent/property-range.vue' /* webpackChunkName: "pages/setting/real-estate-agent/property-range" */))
const _cb30d6d4 = () => interopDefault(import('../pages/setting/staff/registration.vue' /* webpackChunkName: "pages/setting/staff/registration" */))
const _e5b87944 = () => interopDefault(import('../pages/setting/step-mail/registration.vue' /* webpackChunkName: "pages/setting/step-mail/registration" */))
const _cc3cba7e = () => interopDefault(import('../pages/message/line/registration/success.vue' /* webpackChunkName: "pages/message/line/registration/success" */))
const _1f302233 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _3ed0a366 = () => interopDefault(import('../pages/message/mail/edit/_id.vue' /* webpackChunkName: "pages/message/mail/edit/_id" */))
const _46c2c56a = () => interopDefault(import('../pages/agreement/log/_id.vue' /* webpackChunkName: "pages/agreement/log/_id" */))
const _8d2b7290 = () => interopDefault(import('../pages/agreement/registration/_id.vue' /* webpackChunkName: "pages/agreement/registration/_id" */))
const _1dbe3d27 = () => interopDefault(import('../pages/customer/edit/_id.vue' /* webpackChunkName: "pages/customer/edit/_id" */))
const _19e68ec4 = () => interopDefault(import('../pages/media/registration/_id.vue' /* webpackChunkName: "pages/media/registration/_id" */))
const _5454e6c9 = () => interopDefault(import('../pages/project/registration/_id.vue' /* webpackChunkName: "pages/project/registration/_id" */))
const _12cccbad = () => interopDefault(import('../pages/response/add/_id.vue' /* webpackChunkName: "pages/response/add/_id" */))
const _0961db00 = () => interopDefault(import('../pages/setting/announcement/_id.vue' /* webpackChunkName: "pages/setting/announcement/_id" */))
const _33eb9026 = () => interopDefault(import('../pages/setting/staff/_id.vue' /* webpackChunkName: "pages/setting/staff/_id" */))
const _3fc0c825 = () => interopDefault(import('../pages/setting/step-mail/_id.vue' /* webpackChunkName: "pages/setting/step-mail/_id" */))
const _7fa53e70 = () => interopDefault(import('../pages/shop/message/_id.vue' /* webpackChunkName: "pages/shop/message/_id" */))
const _2fc87cfb = () => interopDefault(import('../pages/achievement/_id.vue' /* webpackChunkName: "pages/achievement/_id" */))
const _f4c279d4 = () => interopDefault(import('../pages/agreement/_id.vue' /* webpackChunkName: "pages/agreement/_id" */))
const _4832b1ac = () => interopDefault(import('../pages/client-history/_id.vue' /* webpackChunkName: "pages/client-history/_id" */))
const _3ff31238 = () => interopDefault(import('../pages/customer/_id.vue' /* webpackChunkName: "pages/customer/_id" */))
const _6fab3370 = () => interopDefault(import('../pages/media/_id.vue' /* webpackChunkName: "pages/media/_id" */))
const _26074936 = () => interopDefault(import('../pages/project/_id.vue' /* webpackChunkName: "pages/project/_id" */))
const _51ec3fe9 = () => interopDefault(import('../pages/property/_property.vue' /* webpackChunkName: "pages/property/_property" */))
const _726d5b06 = () => interopDefault(import('../pages/property/_property/contact/index.vue' /* webpackChunkName: "pages/property/_property/contact/index" */))
const _3d26b0ab = () => interopDefault(import('../pages/property/_property/contact/confirm.vue' /* webpackChunkName: "pages/property/_property/contact/confirm" */))
const _f86500a6 = () => interopDefault(import('../pages/property/_property/contact/send.vue' /* webpackChunkName: "pages/property/_property/contact/send" */))
const _eda8d8ca = () => interopDefault(import('../pages/response/_id.vue' /* webpackChunkName: "pages/response/_id" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/achievement",
    component: _e0f3bc5a,
    name: "achievement"
  }, {
    path: "/advertise",
    component: _bbb039ea,
    name: "advertise"
  }, {
    path: "/agreement",
    component: _6e3965a4,
    name: "agreement"
  }, {
    path: "/calendar",
    component: _20031f30,
    name: "calendar"
  }, {
    path: "/customer",
    component: _3f605bd0,
    name: "customer"
  }, {
    path: "/details",
    component: _647ed4b4,
    name: "details"
  }, {
    path: "/goals",
    component: _468a4038,
    name: "goals"
  }, {
    path: "/maisoku",
    component: _48732082,
    name: "maisoku"
  }, {
    path: "/media",
    component: _61950f08,
    name: "media"
  }, {
    path: "/privacy",
    component: _5c2d03a9,
    name: "privacy"
  }, {
    path: "/project",
    component: _6187e486,
    name: "project"
  }, {
    path: "/response",
    component: _1c7e1073,
    name: "response"
  }, {
    path: "/achievement/output",
    component: _292f5e60,
    name: "achievement-output"
  }, {
    path: "/achievement/registration",
    component: _e429c170,
    name: "achievement-registration"
  }, {
    path: "/advertise/analysis",
    component: _729ef19a,
    name: "advertise-analysis"
  }, {
    path: "/advertise/management",
    component: _339acd5a,
    name: "advertise-management"
  }, {
    path: "/advertise/registration",
    component: _1f7fe1e0,
    name: "advertise-registration"
  }, {
    path: "/calendar/registration",
    component: _0014cf4b,
    name: "calendar-registration"
  }, {
    path: "/customer/registration",
    component: _01581eab,
    name: "customer-registration"
  }, {
    path: "/goals/analysis",
    component: _1dceafba,
    name: "goals-analysis"
  }, {
    path: "/goals/setting",
    component: _fdcb403c,
    name: "goals-setting"
  }, {
    path: "/line/tracking",
    component: _3191af16,
    name: "line-tracking"
  }, {
    path: "/maisoku/export",
    component: _7f972997,
    name: "maisoku-export"
  }, {
    path: "/maisoku/template",
    component: _2e50e89d,
    name: "maisoku-template"
  }, {
    path: "/response/registration",
    component: _11877ca8,
    name: "response-registration"
  }, {
    path: "/setting/announcement",
    component: _d8852bd0,
    name: "setting-announcement"
  }, {
    path: "/setting/import",
    component: _23a94376,
    name: "setting-import"
  }, {
    path: "/setting/mail-template",
    component: _66d5fe32,
    name: "setting-mail-template"
  }, {
    path: "/setting/matching-mail",
    component: _0022172e,
    name: "setting-matching-mail"
  }, {
    path: "/setting/shop",
    component: _c52a28f8,
    name: "setting-shop"
  }, {
    path: "/setting/staff",
    component: _87762f76,
    name: "setting-staff"
  }, {
    path: "/setting/step-mail",
    component: _facf6b06,
    name: "setting-step-mail"
  }, {
    path: "/setting/tag",
    component: _d8206202,
    name: "setting-tag"
  }, {
    path: "/shop/document-template",
    component: _b21358f6,
    name: "shop-document-template"
  }, {
    path: "/shop/message",
    component: _cb7d7340,
    name: "shop-message"
  }, {
    path: "/message/line/edit",
    component: _7d7df59e,
    name: "message-line-edit"
  }, {
    path: "/message/line/log",
    component: _5f97fa60,
    name: "message-line-log"
  }, {
    path: "/message/line/registration",
    component: _7f5a84e0,
    name: "message-line-registration"
  }, {
    path: "/message/line/select",
    component: _7e7a6490,
    name: "message-line-select"
  }, {
    path: "/message/line/send",
    component: _685b681c,
    name: "message-line-send"
  }, {
    path: "/message/mail/log",
    component: _0893dd23,
    name: "message-mail-log"
  }, {
    path: "/message/mail/registration",
    component: _2b81652c,
    name: "message-mail-registration"
  }, {
    path: "/message/mail/select",
    component: _56b3e6ed,
    name: "message-mail-select"
  }, {
    path: "/message/mail/send",
    component: _4248448e,
    name: "message-mail-send"
  }, {
    path: "/setting/announcement/registration",
    component: _0223ef63,
    name: "setting-announcement-registration"
  }, {
    path: "/setting/import/registration",
    component: _70df1f76,
    name: "setting-import-registration"
  }, {
    path: "/setting/real-estate-agent/property-range",
    component: _c7acdc62,
    name: "setting-real-estate-agent-property-range"
  }, {
    path: "/setting/staff/registration",
    component: _cb30d6d4,
    name: "setting-staff-registration"
  }, {
    path: "/setting/step-mail/registration",
    component: _e5b87944,
    name: "setting-step-mail-registration"
  }, {
    path: "/message/line/registration/success",
    component: _cc3cba7e,
    name: "message-line-registration-success"
  }, {
    path: "/",
    component: _1f302233,
    name: "index"
  }, {
    path: "/message/mail/edit/:id?",
    component: _3ed0a366,
    name: "message-mail-edit-id"
  }, {
    path: "/agreement/log/:id?",
    component: _46c2c56a,
    name: "agreement-log-id"
  }, {
    path: "/agreement/registration/:id?",
    component: _8d2b7290,
    name: "agreement-registration-id"
  }, {
    path: "/customer/edit/:id?",
    component: _1dbe3d27,
    name: "customer-edit-id"
  }, {
    path: "/media/registration/:id?",
    component: _19e68ec4,
    name: "media-registration-id"
  }, {
    path: "/project/registration/:id?",
    component: _5454e6c9,
    name: "project-registration-id"
  }, {
    path: "/response/add/:id?",
    component: _12cccbad,
    name: "response-add-id"
  }, {
    path: "/setting/announcement/:id",
    component: _0961db00,
    name: "setting-announcement-id"
  }, {
    path: "/setting/staff/:id",
    component: _33eb9026,
    name: "setting-staff-id"
  }, {
    path: "/setting/step-mail/:id?",
    component: _3fc0c825,
    name: "setting-step-mail-id"
  }, {
    path: "/shop/message/:id",
    component: _7fa53e70,
    name: "shop-message-id"
  }, {
    path: "/achievement/:id",
    component: _2fc87cfb,
    name: "achievement-id"
  }, {
    path: "/agreement/:id",
    component: _f4c279d4,
    name: "agreement-id"
  }, {
    path: "/client-history/:id?",
    component: _4832b1ac,
    name: "client-history-id"
  }, {
    path: "/customer/:id",
    component: _3ff31238,
    name: "customer-id"
  }, {
    path: "/media/:id",
    component: _6fab3370,
    name: "media-id"
  }, {
    path: "/project/:id",
    component: _26074936,
    name: "project-id"
  }, {
    path: "/property/:property?",
    component: _51ec3fe9,
    name: "property-property",
    children: [{
      path: "contact",
      component: _726d5b06,
      name: "property-property-contact"
    }, {
      path: "contact/confirm",
      component: _3d26b0ab,
      name: "property-property-contact-confirm"
    }, {
      path: "contact/send",
      component: _f86500a6,
      name: "property-property-contact-send"
    }]
  }, {
    path: "/response/:id",
    component: _eda8d8ca,
    name: "response-id"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
